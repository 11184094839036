import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader
} from "@mui/material";
import Page from "../../components/Page";
import { styled } from "@mui/system";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";

const MyPage = styled(Page)({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
});

const LoginView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.UserReducer.isLoading);

  const [value, setValue] = React.useState(0);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            {...other}
        >
            <Box style={{padding:"0px"}}>
                {children}
            </Box>
        </div>
    )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

  return (
    <MyPage title="Login">
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            >
            <Tabs value={value} onChange={handleTabChange} centered style={{borderBottom:'2px solid black', paddingBottom:'10px'}}>
                    <Tab label="Home" style={{color:"rgba(167,11,32)", fontSize:"large", fontWeight:"bold"}}/>
                    <Tab label="Login" style={{color:"rgba(252,120,55)", fontSize:"large", fontWeight:"bold"}}/>
                </Tabs>
                <TabPanel value={value} index={0}>
                    <HomePage/>
                </TabPanel>
                <TabPanel value={value} index={1}> 
                   <LoginPage/>
                </TabPanel>
        </Box>
    </MyPage>
  );
};

export default LoginView;
